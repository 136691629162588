<template>
  <svg
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m383.226 492h-93.998v-140.733c81.942-15.588 144.081-87.714 144.081-174.073 0-97.705-79.54-177.194-177.309-177.194s-177.309 79.489-177.309 177.194c0 86.358 62.139 158.485 144.081 174.073v140.733h-93.998c-5.523 0-10 4.478-10 10s4.477 10 10 10h254.451c5.523 0 10-4.478 10-10s-4.476-10-9.999-10zm-284.535-314.806c0-86.676 70.568-157.194 157.309-157.194s157.309 70.518 157.309 157.194-70.568 157.194-157.309 157.194-157.309-70.517-157.309-157.194zm144.081 314.806v-138.101c4.368.323 8.779.489 13.228.489s8.86-.166 13.228-.489v138.101z"
      />
      <path
        d="m389.309 177.194c0-73.443-59.802-133.194-133.309-133.194s-133.309 59.751-133.309 133.194 59.802 133.194 133.309 133.194 133.309-59.75 133.309-133.194zm-246.618 0c0-62.416 50.83-113.194 113.309-113.194s113.309 50.778 113.309 113.194c0 62.415-50.83 113.193-113.309 113.193s-113.309-50.778-113.309-113.193z"
      />
      <path
        d="m267.355 197.729c23.581 0 42.766-18.902 42.766-42.137 0-23.233-19.185-42.136-42.766-42.136h-31.708c-.647.003-4.373.08-7.257 3.122-2.648 2.793-2.74 6.141-2.743 6.877v113.878c0 5.522 4.477 10 10 10s10-4.478 10-10v-39.52c7.259-.039 16.75-.084 21.708-.084zm0-64.273c12.34 0 22.766 10.137 22.766 22.136s-10.425 22.137-22.766 22.137c-4.935 0-14.295.045-21.544.084-.04-7.454-.085-17.183-.085-22.221 0-4.26-.026-14.376-.049-22.136z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "parking-sign",
};
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
