import Vue from "vue";
export default Vue.extend({
  methods: {
    image(value: string): string {
      return require(`@/assets/images/${value}`);
    },
    resize(img, size = 600) {
      return img.replace("upload/", `upload/c_fit,h_${size}/`);
    },
  },
});
