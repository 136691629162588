import { REVIEWS } from "@/consts/ROUTES";
import { Review } from "@/models/review.model";
import { axios } from "@/store/fetch";

export default {
  namespaced: true,
  state: () => ({
    items: [],
  }),
  mutations: {
    setReviews(state: any, payload: Review[]) {
      state.items = payload;
    },
  },
  actions: {
    getReviews({ commit }: any) {
      axios
        .get(REVIEWS)
        .then(({ data }) => commit("setReviews", data.reviews as Review[]));
    },
  },
  getters: {},
};
