import { USERS } from "@/consts/ROUTES";
import { User } from "@/models/user.model";
import { axios } from "@/store/fetch";

export default {
  namespaced: true,
  state: () => ({
    items: [],
  }),
  mutations: {
    setUsers(state: any, payload: User[]) {
      state.items = payload;
    },
  },
  actions: {
    getUsers({ commit }: any) {
      axios.get(USERS).then(({ data }) => commit("setUsers", data as User[]));
    },
  },
  getters: {},
};
