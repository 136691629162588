<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.6"
    height="13"
    viewBox="0 0 10.6 13"
  >
    <g id="share" transform="translate(-3.5 -1.5)">
      <path
        id="Path_198"
        data-name="Path 198"
        d="M4,12v4.8A1.2,1.2,0,0,0,5.2,18h7.2a1.2,1.2,0,0,0,1.2-1.2V12"
        transform="translate(0 -4)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        id="Path_199"
        data-name="Path 199"
        d="M12.8,4.4,10.4,2,8,4.4"
        transform="translate(-1.6)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <line
        id="Line_28"
        data-name="Line 28"
        y2="7.8"
        transform="translate(8.8 2)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "share-icon",
};
</script>

<style scoped>
svg {
  stroke: currentColor;
}
</style>
