export default {
  namespaced: true,
  state: () => ({
    currentFilters: {
      city: "Beersheba",
      priceFrom: 80,
      priceTo: 400,
      check_in_out: {
        startDate: new Date("2022-03-20"),
        endDate: new Date("2022-03-30"),
      },
      houseType: ["House", "Apartment"],
      amenities: ["pools", "bedrooms"],
      adults: 4,
      children: 2,
    },
    houseTypes: ["House", "Apartment", "Guesthouse", "Hotel"],
  }),
  getters: {
    guests(state) {
      return state.currentFilters.adults + state.currentFilters.children;
    },
  },
  mutations: {
    setFilter(state, payload) {
      state.currentFilters[payload.field] = payload.value;
    },
  },
};
