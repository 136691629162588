<template>
  <svg
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m419.593 259.229h-160.335c-38.872 0-70.497 31.703-70.497 70.671v26.349c0 5.522 4.477 10 10 10h220.832c5.523 0 10-4.478 10-10v-87.02c0-5.523-4.477-10-10-10zm-10 87.019h-200.832v-16.349c0-27.94 22.653-50.671 50.497-50.671h150.335z"
      />
      <path
        d="m502 389.565h-9.177v-173.148c0-.025.001-.051.001-.076v-71.253c0-22.822-18.575-41.39-41.407-41.39h-341.935c-9.342 0-16.942-7.636-16.942-17.021s7.6-17.021 16.942-17.021h294.078c5.523 0 10-4.478 10-10s-4.477-10-10-10h-294.078c-20.37 0-36.942 16.607-36.942 37.021s16.572 37.021 36.942 37.021h341.935c11.804 0 21.407 9.596 21.407 21.39v54.228h-24.944v-21.067c0-15.42-12.545-27.965-27.965-27.965h-46.747c-6.833 0-13.102 2.464-17.965 6.55-4.863-4.086-11.131-6.55-17.964-6.55h-46.747c-15.42 0-27.965 12.545-27.965 27.965v21.067h-10.962c-52.316 0-103.379 18.353-143.774 51.669-13.722 11.293-26.106 24.209-36.813 38.393-6.137 8.139-11.772 16.77-16.74 25.638-5.374 9.554-10.1 19.62-14.047 29.919-2.998 7.816-5.604 15.964-7.75 24.222l-6.946 20.41h-15.495c-5.523 0-10 4.478-10 10v52.777c0 5.522 4.477 10 10 10h492c5.523 0 10-4.478 10-10v-52.777c0-5.524-4.477-10.002-10-10.002zm-128.832-219.282h46.747c4.392 0 7.965 3.573 7.965 7.965v21.067h-62.677v-21.067c0-4.392 3.573-7.965 7.965-7.965zm-90.641 7.965c0-4.392 3.573-7.965 7.965-7.965h46.747c4.392 0 7.964 3.573 7.964 7.965v21.067h-62.676zm-200.499 129.979c6.036 3.79 9.938 10.559 9.938 17.916 0 11.633-9.424 21.097-21.007 21.097-3.238 0-6.401-.75-9.256-2.163 2.958-6.946 6.299-13.736 9.975-20.271 3.173-5.665 6.64-11.216 10.35-16.579zm-30.533 67.017c.081-.238.153-.479.216-.724.922-3.573 1.938-7.124 3.042-10.629 5.075 2.192 10.583 3.348 16.207 3.348 22.611 0 41.007-18.436 41.007-41.097 0-13.89-6.783-26.366-17.707-33.859 7.953-9.375 16.747-18.043 26.248-25.862 36.83-30.377 83.373-47.105 131.057-47.105h20.688c.091.002.182.004.274.004h165.353c.092 0 .183-.001.274-.004h34.669v170.25h-426.202zm440.505 67.099h-472v-32.777h472z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "iron-icon",
};
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
