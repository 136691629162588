import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import icons from "@/assets/icons";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: icons,
  },
  theme: {
    themes: {
      light: {
        primary: "#7ED9D9",
      },
    },
  },
});
