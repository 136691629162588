<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.015"
    height="12.568"
    viewBox="0 0 17.015 12.568"
  >
    <g id="Group_46" data-name="Group 46" transform="translate(-335.5 -19)">
      <line
        id="Line_18"
        data-name="Line 18"
        x2="11.584"
        transform="translate(336.224 21.534)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="1"
      />
      <line
        id="Line_19"
        data-name="Line 19"
        x1="12"
        transform="translate(340 29)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="1"
      />
      <g
        id="Ellipse_7"
        data-name="Ellipse 7"
        transform="translate(347.446 19)"
        fill="#fff"
        stroke="#000"
        stroke-width="1"
      >
        <circle cx="2.534" cy="2.534" r="2.534" stroke="none" />
        <circle cx="2.534" cy="2.534" r="2.034" fill="none" />
      </g>
      <g
        id="Ellipse_8"
        data-name="Ellipse 8"
        transform="translate(340.568 31.568) rotate(180)"
        fill="#fff"
        stroke="#000"
        stroke-width="1"
      >
        <circle cx="2.534" cy="2.534" r="2.534" stroke="none" />
        <circle cx="2.534" cy="2.534" r="2.034" fill="none" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "filters-icon",
};
</script>

<style scoped></style>
