<template>
  <section
    v-if="carouselReviews.length > 0"
    class="home-reviews blue-grey lighten-5 pt-8 pt-sm-16 pb-sm-8"
  >
    <h2 class="text-center text-h5 font-weight-medium mb-7 mb-md-16">
      {{ HOME_REVIEWS_TITLE }}
    </h2>
    <v-container>
      <site-carousel :items="carouselReviews" />
    </v-container>
  </section>
</template>

<script>
import { HOME_REVIEWS_TITLE } from "@/consts/CMS";
import { mapState, mapActions } from "vuex";
import SiteCarousel from "@/components/common/carousel/site-carousel.vue";
import moment from "moment";

export default {
  name: "home-reviews",
  components: { SiteCarousel },
  data: () => ({
    HOME_REVIEWS_TITLE,
    slide: 0,
  }),
  computed: {
    ...mapState({
      reviews: (state) => state.reviewsModule.items,
      carouselReviews() {
        return this.reviews.map((r) => ({
          rating: +(r.normilized_scrore_overall / 2).toFixed(),
          body: r.public,
          name: r.source,
          date: moment(r.created_at).format("MMM D, YYYY"),
          image: r.listing.picture.replace("h_200", "h_500"),
        }));
      },
    }),
  },
  methods: {
    ...mapActions("reviewsModule", ["getReviews"]),
  },
  mounted() {
    this.getReviews();
  },
};
</script>

<style lang="scss"></style>
