<template>
  <v-tooltip tag="div" key="23" bottom>
    {{ item.type }}
    <template #activator="{ on, attrs }">
      <div class="d-inline-flex align-center mr-4">
        <v-icon
          v-bind="attrs"
          small
          v-on="on"
          color="blue-grey lighten-1"
          class="mr-2"
          style="font-size: 12px"
        >
          {{ AMENITY_ICONS[item.type] }}
        </v-icon>
        <span class="text-caption blue-grey--text font-weight-medium">{{
          item.count
        }}</span>
      </div>
    </template>
  </v-tooltip>
</template>

<script>
import { AMENITY_ICONS } from "@/consts/AMENITIES";

export default {
  name: "amenity-item",
  props: ["item"],
  data: () => ({ AMENITY_ICONS }),
};
</script>
