import { axios } from "@/store/fetch";
import { SETTINGS } from "@/consts/ROUTES";

const changeFavicon = (src) => {
  const url = src.replace("upload/", `upload/w_50,h_50,c_fit/`);
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link["type"] = "image/x-icon";
  link["rel"] = "shortcut icon";
  link["href"] = url;
};

export default {
  state: () => ({
    settings: {},
  }),
  mutations: {
    setSettings(state: any, payload: any) {
      state.settings = payload;
    },
  },
  actions: {
    getSettings({ commit }: any) {
      axios.get(SETTINGS).then(({ data }) => {
        document.title = data.settings.site_title || "Booking";
        window["config"] = Object.assign({}, data.settings);
        changeFavicon(data.settings.logo);
        commit("setSettings", data.settings);
      });
    },
  },
  getters: {},
};
