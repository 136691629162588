<template>
  <div id="snackbars">
    <transition-group name="list">
      <a
        v-for="snack in snackbars"
        :key="snack.snackId"
        class="snackbars-link text-decoration-none p-0 d-block"
        @click="onSnackClick(snack)"
        @mouseenter="snack.timer.stop"
        @mouseleave="snack.timer.start"
      >
        <v-card elevation="3">
          <div class="snackbar-content">
            <div
              v-if="['success', 'error'].includes(snack.type)"
              class="indication-container"
            >
              <div :class="`indication-line ${snack.type}`" />
              <v-icon :class="`${snack.type}--text`" large>
                mdi-{{
                  snack.type === "success" ? "check" : "close"
                }}-circle-outline
              </v-icon>
              <v-icon
                v-if="snack.link"
                class="info--text mx-3"
                large
                @click="openUrl(snack.link, '_blank')"
                >mdi-open-in-new
              </v-icon>
            </div>

            <span class="black--text text-body-2 full">
              {{ snack.text }}
            </span>

            <div class="snackbar-actions">
              <v-btn
                icon
                color="grey"
                @click.prevent.stop="close(snack.snackId)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
      </a>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GlobalSnack",
  computed: {
    ...mapGetters(["snackbars"]),
  },
  methods: {
    onSnackClick(snack) {
      snack.onClick && snack.onClick();
      this.close(snack.snackId);
    },
    close(snackId) {
      this.$store.commit("removeSnack", snackId);
    },
  },
};
</script>

<style scoped>
#snackbars {
  position: fixed;
  top: 66px;
  right: 0;
  z-index: 9999;
}

.snackbars-link {
  display: block;
  transition: all 0.5s ease-in-out;
  margin: 8px;
}

.snackbar-content {
  display: flex;
  align-items: center;
  padding: 12px;
}

.snackbar-actions {
  margin: -8px -8px 0 8px;
}

.indication-container {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.indication-line {
  width: 7px;
  height: 100%;
  margin-right: 12px;
  border-radius: 3px;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
</style>
