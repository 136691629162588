<template>
  <v-container>
    <h1
      :style="isMobile ? '' : 'text-shadow: 3px 1px 20px rgba(0, 0, 0, 0.5)'"
      class="text-center text-h4 text-sm-h2 font-weight-semibold mb-2 mb-sm-6"
      v-text="title"
    />
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6" lg="5" xl="4">
        <div
          :style="
            isMobile ? '' : 'text-shadow: 3px 1px 20px rgba(0, 0, 0, 0.5)'
          "
          class="text-center text-body-2 text-sm-body-1 font-weight-regular"
          v-text="subtitle"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { HOME_TITLE } from "@/consts/CMS";

export default {
  name: "hello-world",
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      HOME_TITLE,
      amount: 1,
    };
  },
};
</script>
