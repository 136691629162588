import { render, staticRenderFns } from "./site-carousel-item.vue?vue&type=template&id=e329b634&scoped=true"
import script from "./site-carousel-item.vue?vue&type=script&lang=js"
export * from "./site-carousel-item.vue?vue&type=script&lang=js"
import style0 from "./site-carousel-item.vue?vue&type=style&index=0&id=e329b634&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e329b634",
  null
  
)

export default component.exports