<template>
  <svg
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m339.74 65.029h59.745c5.522 0 10-4.478 10-10s-4.478-10-10-10h-59.745c-5.522 0-10 4.478-10 10s4.478 10 10 10z"
      />
      <path
        d="m418.958 0h-325.916c-20.918 0-37.937 17.019-37.937 37.937v436.127c0 20.918 17.018 37.937 37.937 37.937h325.916c20.918 0 37.937-17.019 37.937-37.937v-436.127c0-20.918-17.019-37.937-37.937-37.937zm-343.853 37.937c0-9.891 8.047-17.937 17.937-17.937h325.916c9.891 0 17.937 8.046 17.937 17.937v52.123h-131.383v-34.677c0-5.522-4.478-10-10-10h-182.997c-5.523 0-10 4.478-10 10v34.677h-27.41zm210.407 52.123h-162.997v-24.677h162.997zm151.383 384.003c0 9.891-8.046 17.937-17.937 17.937h-325.916c-9.89 0-17.937-8.046-17.937-17.937v-364.003h361.789v364.003z"
      />
      <path
        d="m109.875 297.383c0 80.048 65.551 145.171 146.125 145.171s146.125-65.123 146.125-145.171-65.552-145.171-146.125-145.171-146.125 65.123-146.125 145.171zm272.25 0c0 69.02-56.579 125.171-126.125 125.171-69.545 0-126.125-56.151-126.125-125.171s56.58-125.171 126.125-125.171c69.546 0 126.125 56.151 126.125 125.171z"
      />
      <path
        d="m154.658 297.383c0 55.527 45.462 100.702 101.342 100.702 25.323 0 49.569-9.335 68.271-26.286 2.091-1.896 3.284-4.587 3.284-7.409s-1.193-5.514-3.284-7.409c-16.843-15.266-26.502-36.987-26.502-59.598 0-22.609 9.659-44.333 26.502-59.599 2.092-1.896 3.284-4.587 3.284-7.409 0-2.823-1.193-5.514-3.284-7.41-18.703-16.949-42.948-26.284-68.271-26.284-55.88 0-101.342 45.174-101.342 100.702zm123.112 0c0 24.352 8.962 47.872 24.901 66.101-13.606 9.475-29.84 14.602-46.671 14.602-44.852 0-81.342-36.203-81.342-80.702s36.49-80.702 81.342-80.702c16.831 0 33.063 5.127 46.671 14.602-15.94 18.227-24.901 41.747-24.901 66.099z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "washing-machine",
};
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
