<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.207"
    height="13.414"
    viewBox="0 0 7.207 13.414"
  >
    <path
      id="chevron-left"
      d="M15,18,9,12l6-6"
      transform="translate(-8.5 -5.293)"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
  </svg>
</template>

<script>
export default {
  name: "chevron-left",
};
</script>

<style scoped>
svg {
  stroke: currentColor;
}
</style>
