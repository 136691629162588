<template>
  <svg
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m502 369.988h-14.489v-282.53c0-15.181-12.351-27.531-27.532-27.531h-407.958c-15.181 0-27.532 12.351-27.532 27.531v282.53h-14.489c-5.523 0-10 4.478-10 10v39.767c0 17.82 14.498 32.318 32.318 32.318h447.363c17.82 0 32.318-14.498 32.318-32.318v-39.767c.001-5.522-4.476-10-9.999-10zm-457.511-282.53c0-4.152 3.379-7.531 7.532-7.531h407.957c4.153 0 7.532 3.379 7.532 7.531v282.53h-119.978c-5.523 0-10 4.478-10 10v5.215h-163.064v-5.215c0-5.522-4.477-10-10-10h-119.979zm447.511 332.297c0 6.792-5.526 12.318-12.318 12.318h-447.364c-6.792 0-12.318-5.526-12.318-12.318v-29.767h134.468v5.215c0 5.522 4.477 10 10 10h183.063c5.523 0 10-4.478 10-10v-5.215h134.469z"
      />
      <path
        d="m420.125 320.14v-183.83c0-5.522-4.477-10-10-10h-308.25c-5.523 0-10 4.478-10 10v183.83c0 5.522 4.477 10 10 10h308.251c5.522 0 9.999-4.478 9.999-10zm-20-10h-288.25v-163.83h288.251v163.83z"
      />
      <path
        d="m185.411 226.107c18.822-18.824 43.891-29.191 70.589-29.191s51.767 10.367 70.589 29.191c1.953 1.953 4.512 2.93 7.072 2.93 2.559 0 5.118-.977 7.071-2.929 3.905-3.904 3.906-10.236.001-14.142-22.6-22.603-52.691-35.051-84.732-35.051s-62.132 12.448-84.732 35.051c-3.905 3.905-3.905 10.236 0 14.142 3.905 3.907 10.237 3.906 14.142-.001z"
      />
      <path
        d="m256 218.724c-20.857 0-40.447 8.104-55.16 22.819-3.905 3.905-3.905 10.237 0 14.142 3.906 3.906 10.238 3.905 14.142-.001 10.936-10.937 25.502-16.96 41.017-16.96s30.081 6.023 41.017 16.96c1.953 1.953 4.512 2.93 7.071 2.93s5.118-.977 7.071-2.929c3.905-3.904 3.906-10.236 0-14.142-14.711-14.716-34.301-22.819-55.158-22.819z"
      />
      <path
        d="m256 260.519c-9.666 0-18.753 3.765-25.588 10.601-3.905 3.905-3.904 10.237.001 14.142 3.906 3.906 10.237 3.905 14.143-.001 3.057-3.058 7.121-4.741 11.444-4.741s8.388 1.684 11.444 4.741c1.953 1.953 4.512 2.93 7.072 2.93 2.559 0 5.118-.977 7.071-2.929 3.905-3.904 3.906-10.236.001-14.142-6.835-6.837-15.922-10.601-25.588-10.601z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "wi-fi",
};
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
