export function getTimer(func, timeout) {
  let instance = null;
  const start = () => {
    instance = setTimeout(func, timeout);
  };
  const stop = () => {
    if (instance) {
      clearTimeout(instance);
      instance = null;
    }
  };
  return { start, stop };
}
