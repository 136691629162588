import Vue from "vue";
import round from "lodash/round";
import get from "lodash/fp/get";

export default Vue.extend({
  computed: {
    currencySign() {
      const locale = get("config.locale", window) || "US";
      const currency = new Intl.NumberFormat(`en-${locale}`, {
        style: "currency",
        currency: get("config.currency", window) || "USD",
        maximumFractionDigits: 0,
      });
      return currency.formatToParts(0).find((c) => c.type === "currency")
        ?.value;
    },
  },
  methods: {
    commaList(list: string[]): string {
      return list.join(", ");
    },
    toMoney(num, decimal = 2, currency = "$") {
      return `${currency}${round(num, decimal).toLocaleString()}`;
    },
    parseDate(date, format = "DD MMM YYYY", config = { local: true }) {
      const parsedDate = config?.local
        ? this.$moment(date)
        : this.$moment.utc(date);
      if (parsedDate) {
        return parsedDate.format(format);
      }
    },
    daysDifference([startDate, endDate]): number {
      return this.$moment
        .utc(endDate)
        .diff(this.$moment.utc(startDate), "days");
    },
    filtersDateRange([startDate, endDate]) {
      const [startDay, endDay] = [
        startDate.split(" ")[0],
        endDate.split(" ")[0],
      ];
      const [startMonth, endMonth] = [
        startDate.split(" ")[1],
        endDate.split(" ")[1],
      ];
      if (startMonth === endMonth)
        return `${startDay} - ${endDay} ${startMonth}`;
      else return `${startDate} - ${endDate}`;
    },
    cutText(text, limit) {
      return text.length >= limit ? `${text.substring(0, limit)}...` : text;
    },
  },
});
