var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel"},[_c('v-carousel',{attrs:{"value":_vm.slide,"height":"","interval":_vm.interval,"cycle":_vm.cycle,"hide-delimiters":"","show-arrows":""},on:{"change":function($event){return _vm.$emit('update:slide', $event)}}},_vm._l((_vm.items),function(item){return _c('v-carousel-item',{key:item.id,attrs:{"eager":_vm.loadAll}},[_c('site-carousel-item',{attrs:{"item":item,"easy":_vm.easy,"easy-contain":_vm.easyContain,"tile":_vm.tile,"listing-id":_vm.listingId,"item-height":_vm.itemHeight,"eager":""}})],1)}),1),(_vm.showDots)?_c('div',{class:[
      'carousel-dots',
      { 'carousel-dots-easy': _vm.easy },
      'd-flex',
      'justify-center',
    ]},_vm._l((_vm.items.length),function(d){return _c('button',{key:`dot-${d - 1}`,class:[
        {
          active: _vm.slide === d - 1,
        },
        'carousel-dots-item',
      ],on:{"click":function($event){return _vm.$emit('update:slide', d - 1)}}})}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }