<template>
  <svg
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m442.023 492h-15.562v-454.854c0-20.482-16.664-37.146-37.146-37.146h-266.63c-20.482 0-37.146 16.664-37.146 37.146v454.854h-15.562c-5.523 0-10 4.477-10 10s4.477 10 10 10h372.046c5.523 0 10-4.477 10-10s-4.477-10-10-10zm-292.484 0v-428h212.922v428zm232.922 0v-438c0-5.523-4.477-10-10-10h-232.922c-5.523 0-10 4.477-10 10v438h-24v-454.854c0-9.454 7.691-17.146 17.146-17.146h266.631c9.454 0 17.146 7.691 17.146 17.146v454.854z"
      />
      <path
        d="m327.461 93h-142.922c-5.523 0-10 4.477-10 10v73.553c0 5.523 4.477 10 10 10h142.922c5.523 0 10-4.477 10-10v-73.553c0-5.523-4.477-10-10-10zm-10 73.553h-122.922v-53.553h122.922z"
      />
      <path
        d="m184.539 331.553h36.418c5.523 0 10-4.477 10-10v-88.085c0-5.523-4.477-10-10-10h-36.418c-5.523 0-10 4.477-10 10v88.085c0 5.523 4.477 10 10 10zm10-88.085h16.418v68.085h-16.418z"
      />
      <path
        d="m324.571 448.191h-137.142c-5.523 0-10 4.477-10 10s4.477 10 10 10h137.142c5.523 0 10-4.477 10-10s-4.477-10-10-10z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "door-room",
};
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
