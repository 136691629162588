<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18.004"
    viewBox="0 0 18 18.004"
  >
    <path
      class="a"
      d="M22.289,21.194l-5.006-5.053a7.134,7.134,0,1,0-1.083,1.1l4.973,5.02a.77.77,0,0,0,1.087.028A.775.775,0,0,0,22.289,21.194ZM11.676,17.3a5.633,5.633,0,1,1,3.984-1.65A5.6,5.6,0,0,1,11.676,17.3Z"
      transform="translate(-4.5 -4.493)"
    />
  </svg>
</template>

<script>
export default {
  name: "search-icon",
};
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
