<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.55 24.01">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="_001-no-smoking" data-name=" 001-no-smoking">
          <path
            id="Path_394"
            data-name="Path 394"
            d="M29.15,9.57h-9.9l7.26-7.26a.4.4,0,0,0,0-.56h0L25.2.43a.41.41,0,0,0-.57,0h0L15.5,9.57H.4A.4.4,0,0,0,0,10v4.38a.39.39,0,0,0,.4.4h9.9L3,22a.41.41,0,0,0,0,.57H3l1.31,1.3a.41.41,0,0,0,.57,0h0l9.14-9.13H29.15a.4.4,0,0,0,.4-.4V10a.4.4,0,0,0-.4-.4ZM.81,10.38h2V14h-2Zm2.83,0h11L11.11,14H3.64ZM4.64,23l-.74-.73,21-21,.73.74Zm13.8-12.66h3.84V14H14.86ZM28.74,14H23.09V10.38h5.65Z"
          />
          <path
            id="Path_395"
            data-name="Path 395"
            d="M1.76,7.75h.06a.42.42,0,0,0,.36-.22l0-.07a2.32,2.32,0,0,1,2.5-1.2A5.33,5.33,0,0,0,11,2.08,5.37,5.37,0,0,0,11.12,1V.42A.42.42,0,0,0,10.73,0a.37.37,0,0,0-.36.21L9.66,1.38A4.57,4.57,0,0,1,6.21,3.54l-2,.2a3.11,3.11,0,0,0-2.8,3.41,1.26,1.26,0,0,0,0,.27A.4.4,0,0,0,1.76,7.75Zm.88-2.29a2.28,2.28,0,0,1,1.62-.91l2-.2A5.4,5.4,0,0,0,10.19,2,4.55,4.55,0,0,1,4.87,5.47a3.12,3.12,0,0,0-2.58.65,2.1,2.1,0,0,1,.35-.66Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "no-smoking",
};
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
