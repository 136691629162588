import { FEATURES } from "@/consts/ROUTES";
import { Feature } from "@/models/feature.model";
import { axios } from "@/store/fetch";

export default {
  namespaced: true,
  state: () => ({
    items: [],
  }),
  mutations: {
    setFeatures(state: any, payload: Feature[]) {
      state.items = payload;
    },
  },
  actions: {
    getFeatures({ commit }: any) {
      axios
        .get(FEATURES)
        .then(({ data }) => commit("setFeatures", data as Feature[]));
    },
  },
  getters: {},
};
