var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"listings-item mb-3",attrs:{"elevation":"0"},on:{"click":_vm.listingClick}},[_c('div',{staticClass:"p-relative"},[_c('v-toolbar',{staticClass:"pt-3",attrs:{"color":"transparent","width":"100%","absolute":"","dense":"","flat":""}},[_c('v-spacer')],1)],1),_c('v-row',{class:!_vm.horizontal ? 'flex-column' : ''},[_c('v-col',{attrs:{"cols":"12","lg":_vm.carouselLg}},[_c('site-carousel',{attrs:{"items":_vm.thumbnails,"listing-id":_vm.item.id,"slide":_vm.slideIndex,"easy":"","item-height":"220px"},on:{"update:slide":function($event){_vm.slideIndex=$event}}})],1),_c('v-col',{class:{ 'pt-0': !_vm.horizontal }},[_c('div',{staticClass:"d-flex flex-column fill-height"},[_c('v-card-title',{class:[
            { 'justify-space-between align-center': !_vm.horizontal },
            { 'flex-column-reverse align-start': _vm.horizontal },
            'mb-2 pa-0',
          ],staticStyle:{"font-size":"1.2rem"}},[_c('div',{staticClass:"word-break"},[_vm._v(" "+_vm._s(_vm.item.title)+" "),(_vm.horizontal)?_c('div',{staticClass:"d-flex mt-2"},_vm._l((_vm.listingInfo),function(item){return _c('amenity-item',{key:item.type,attrs:{"item":item}})}),1):_vm._e()]),_c('div',{class:[
              'text-caption text--darken-2 w-full d-inline-flex align-center mt-1',
              { 'justify-space-between': !_vm.horizontal },
            ],style:('color: ' + _vm.primaryColor)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.location),expression:"location"}]},[_vm._v(" "+_vm._s(_vm.location)+" ")]),(!_vm.horizontal)?_c('div',{staticClass:"d-flex"},_vm._l((_vm.listingInfo),function(item){return _c('amenity-item',{key:item.type,attrs:{"item":item}})}),1):_vm._e()])]),_c('div',{staticClass:"mt-auto"},[(_vm.item.extra_info.current_price)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-semibold",domProps:{"textContent":_vm._s(
                _vm.toMoney(
                  _vm.item.extra_info.current_price.our_price,
                  0,
                  _vm.currencySign
                )
              )}}),(
                _vm.item.extra_info.current_price.tourist_price !==
                _vm.item.extra_info.current_price.our_price
              )?_c('span',{staticClass:"ml-1"},[_vm._v("+ Fees")]):_vm._e(),_c('v-icon',{staticClass:"mx-3",attrs:{"size":"3"}},[_vm._v("fas fa-circle")]),_c('span',{staticClass:"nights-amount text-caption text--secondary font-weight-medium"},[_vm._v(_vm._s(_vm.days_count)+" nights")])],1):_vm._e()])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }