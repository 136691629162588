import { Destination } from "@/models/destination.model";
import { TOP_DESTINATIONS } from "@/consts/ROUTES";
import { axios } from "@/store/fetch";

export default {
  namespaced: true,
  state: () => ({
    top_destinations: [],
  }),
  mutations: {
    setTopDestinations(state: any, payload: Destination[]) {
      state.top_destinations = payload;
    },
  },
  actions: {
    getTopDestinations({ commit }: any) {
      axios
        .get(TOP_DESTINATIONS)
        .then(({ data }) =>
          commit("setTopDestinations", data as Destination[])
        );
    },
  },
};
