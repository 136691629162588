export const COMPANY_NAME = "DesignedVR";
export const LOGO = "logo.png";
export const LOGO_WHITE = "white-logo.png";
export const BACKDROP = "backdrop.png";
export const IMAGE_PLACEHOLDER = "image-placeholder.jpg";
export const HOME_TITLE = "Go Together, Go Far";
export const HOME_SUBTITLE =
  "Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed";
export const HOME_LISTINGS_TITLE = "Exactly what you are looking for";
export const HOME_TOP_DESTINATIONS_TITLE = "Most popular destinations";
export const HOME_TOP_DESTINATIONS_BUTTON_TEXT = "View all destinations";
export const HOME_FEATURES_TITLE = "What makes us different?";
export const HOME_REVIEWS_TITLE = "What our guests are saying";
export const HOME_TRIGGER_TITLE = "Ready to go?";
export const HOME_TRIGGER_SUBTITLE =
  "Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed";
export const FOOTER_DESCRIPTION =
  "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.";
export const FOOTER_FORM_TITLE = "Stay in the loop";
export const FOOTER_FORM_DESCRIPTION =
  "Keep up with our featured homes, new locations, and travel tips! Oh, and get $100 off your first stay with us.";
export const LOCATION_IMAGE = "location-placeholder.jpg";
