import Vue from "vue";
export default Vue.extend({
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    isIPad(): boolean {
      return this.$vuetify.breakpoint.sm;
    },
    isLaptop(): boolean {
      return this.$vuetify.breakpoint.md;
    },
    isLargeLaptop(): boolean {
      return this.$vuetify.breakpoint.lg;
    },
    isDesktop(): boolean {
      return this.$vuetify.breakpoint.xl;
    },
    isMobileOrIPad(): boolean {
      return this.isMobile || this.isIPad;
    },
  },
});
