<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="Layer_2" data-name="Layer 2">
          <path
            id="Path_396"
            data-name="Path 396"
            d="M16.29.07,6.14,5.72H2.44A2.44,2.44,0,0,0,0,8.16v7.68a2.45,2.45,0,0,0,2.44,2.45h3.7l10.15,5.64a.58.58,0,0,0,.78-.22.55.55,0,0,0,.07-.28V.57A.57.57,0,0,0,16.57,0a.58.58,0,0,0-.28.07ZM5.71,17.14H2.44a1.3,1.3,0,0,1-1.3-1.3V8.16a1.3,1.3,0,0,1,1.3-1.3H5.71ZM16,22.46,6.86,17.38V6.62L16,1.54Z"
          />
          <path
            id="Path_397"
            data-name="Path 397"
            d="M22,12l1.88-1.88a.56.56,0,0,0,0-.81.58.58,0,0,0-.81,0l-1.88,1.88L19.26,9.31a.57.57,0,0,0-.81.81L20.33,12l-1.88,1.88a.57.57,0,0,0,.81.81l1.88-1.88L23,14.69a.58.58,0,0,0,.81,0,.56.56,0,0,0,0-.81Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "no-sound",
};
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
