import AgeLimit from "@/assets/icons/age-limit.vue";
import BathTub from "@/assets/icons/bath-tub.vue";
import ChevronLeft from "@/assets/icons/chevron-left.vue";
import DoubleBed from "@/assets/icons/double-bed.vue";
import Filters from "@/assets/icons/filters.vue";
import HairDryer from "@/assets/icons/hair-dryer.vue";
import Iron from "@/assets/icons/iron.vue";
import NoSmoking from "@/assets/icons/no-smoking.vue";
import NoSound from "@/assets/icons/no-sound.vue";
import ParkingSign from "@/assets/icons/parking-sign.vue";
import PetsAllowed from "@/assets/icons/pets-allowed.vue";
import RoomDoor from "@/assets/icons/room-door.vue";
import Search from "@/assets/icons/search.vue";
import Share from "@/assets/icons/share.vue";
import SwimmingPool from "@/assets/icons/swimming-pool.vue";
import WashingMachine from "@/assets/icons/washing-machine.vue";
import WiFi from "@/assets/icons/wifi.vue";

export default {
  age_limit: {
    component: AgeLimit,
  },
  bath_tub: {
    component: BathTub,
  },
  chevron_left: {
    component: ChevronLeft,
  },
  double_bed: {
    component: DoubleBed,
  },
  filters: {
    component: Filters,
  },
  hair_dryer: {
    component: HairDryer,
  },
  iron: {
    component: Iron,
  },
  no_smoking: {
    component: NoSmoking,
  },
  no_sound: {
    component: NoSound,
  },
  parking_sign: {
    component: ParkingSign,
  },
  pets_allowed: {
    component: PetsAllowed,
  },
  room_door: {
    component: RoomDoor,
  },
  search: {
    component: Search,
  },
  share: {
    component: Share,
  },
  swimming_pool: {
    component: SwimmingPool,
  },
  washing_machine: {
    component: WashingMachine,
  },
  wifi: {
    component: WiFi,
  },
};
