<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.17 23.99">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="_003-track" data-name=" 003-track">
          <g id="Group_74" data-name="Group 74">
            <g id="Group_73" data-name="Group 73">
              <path
                id="Path_398"
                data-name="Path 398"
                d="M15.13,13.3a.51.51,0,0,0,0,1,.51.51,0,0,0,.51-.51h0A.51.51,0,0,0,15.13,13.3Z"
              />
              <path
                id="Path_399"
                data-name="Path 399"
                d="M19.7,16.05a12.71,12.71,0,0,1-2.56-3.88,4.39,4.39,0,0,0-5.73-2.36,4.43,4.43,0,0,0-2.36,2.36,12.27,12.27,0,0,1-2.73,4h0a4.39,4.39,0,0,0,.52,6.94,4.87,4.87,0,0,0,4,.65,9.15,9.15,0,0,1,4.59,0,4.82,4.82,0,0,0,4-.63,4.39,4.39,0,0,0,.34-7.14Zm-.9,6.29a3.82,3.82,0,0,1-3.12.5,10.29,10.29,0,0,0-5.14,0,3.83,3.83,0,0,1-3.14-.5,3.31,3.31,0,0,1-1.52-2.83A3.35,3.35,0,0,1,7,17a13.13,13.13,0,0,0,3-4.42h0a3.35,3.35,0,0,1,6.2,0h0A14.11,14.11,0,0,0,19,16.8l.05,0a3.37,3.37,0,0,1,.58,4.74,3.16,3.16,0,0,1-.83.76Z"
              />
              <path
                id="Path_400"
                data-name="Path 400"
                d="M16.53,15.33a.52.52,0,0,0-.71-.15.52.52,0,0,0-.15.71h0a14.82,14.82,0,0,0,1.89,2.36.51.51,0,0,0,.72,0,.52.52,0,0,0,0-.7A12.75,12.75,0,0,1,16.53,15.33Z"
              />
              <path
                id="Path_401"
                data-name="Path 401"
                d="M11.43,7A5.14,5.14,0,0,0,12,3.85c-.26-2.2-1.78-4-3.47-3.83A2.61,2.61,0,0,0,6.6,1.43,5.22,5.22,0,0,0,6,4.56C6.46,8.15,9.81,9.82,11.43,7Zm-3.94-5A1.59,1.59,0,0,1,8.77,1c1,0,2,1.36,2.21,3a4.18,4.18,0,0,1-.44,2.5c-1.18,2-3.21.32-3.48-2a4.16,4.16,0,0,1,.43-2.5Z"
              />
              <path
                id="Path_402"
                data-name="Path 402"
                d="M6.53,12.49A4.58,4.58,0,0,0,6,9.65,4.53,4.53,0,0,0,4,7.59a2.78,2.78,0,0,0-2.54-.06A2.81,2.81,0,0,0,0,9.64a4.71,4.71,0,0,0,.51,2.83,4.63,4.63,0,0,0,2,2.06,2.78,2.78,0,0,0,2.54.06A2.74,2.74,0,0,0,6.53,12.49Zm-1.89,1.2A1.78,1.78,0,0,1,3,13.61,3.65,3.65,0,0,1,1.46,12,3.69,3.69,0,0,1,1.05,9.8a1.78,1.78,0,0,1,.88-1.36h0a1.78,1.78,0,0,1,1.62.07,3.65,3.65,0,0,1,1.57,1.61,3.64,3.64,0,0,1,.4,2.2,1.76,1.76,0,0,1-.88,1.36Z"
              />
              <path
                id="Path_403"
                data-name="Path 403"
                d="M16.92,8.42c1.55,0,3-1.69,3.23-3.85S19.26,0,17.42,0c-1.56,0-3,1.69-3.23,3.86S15.08,8.42,16.92,8.42ZM15.21,4c.19-1.6,1.2-3,2.21-3h.12a1.74,1.74,0,0,1,1.22,1,4.24,4.24,0,0,1,.37,2.38c-.2,1.66-1.3,3.08-2.33,2.94h0a1.71,1.71,0,0,1-1.21-1A4.26,4.26,0,0,1,15.21,4Z"
              />
              <path
                id="Path_404"
                data-name="Path 404"
                d="M26.13,9.64a2.64,2.64,0,0,0-2.86-2.4,2.84,2.84,0,0,0-1.1.35,4.89,4.89,0,0,0-2.53,4.9,2.66,2.66,0,0,0,4,2A4.87,4.87,0,0,0,26.13,9.64Zm-3,4a1.78,1.78,0,0,1-1.62.08h0a1.82,1.82,0,0,1-.88-1.37,3.86,3.86,0,0,1,2-3.81,1.62,1.62,0,0,1,2.5,1.29,3.84,3.84,0,0,1-2,3.81Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "pets-allowed",
};
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
