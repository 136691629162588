<template>
  <nav class="d-flex align-center">
    <v-btn
      v-for="(item, index) in menu"
      :key="item.text"
      :color="color"
      target="_blank"
      :class="{ 'ml-2': index > 0 }"
      :href="item.url"
      icon
    >
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </nav>
</template>

<script>
export default {
  name: "site-social-menu",
  props: {
    menu: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
    },
  },
};
</script>

<style scoped></style>
