<template>
  <v-card
    @click="filterByCity"
    class="pointer"
    color="transparent"
    elevation="0"
  >
    <v-img
      class="rounded-lg mb-4"
      :src="item.image || image(IMAGE_PLACEHOLDER)"
      aspect-ratio="1.34"
    />
    <v-card-title class="justify-center pa-0 text-center word-break-normal">
      {{ item.name }}
    </v-card-title>
  </v-card>
</template>

<script>
import { IMAGE_PLACEHOLDER } from "@/consts/CMS";
import MediaMixin from "@/mixins/media-mixin";

export default {
  name: "home-top-destinations-item",
  mixins: [MediaMixin],
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({ IMAGE_PLACEHOLDER }),
  methods: {
    filterByCity() {
      this.$router.push({
        name: "listings",
        query: {
          ...this.$route.query,
          city: this.item.name,
        },
      });
    },
  },
};
</script>

<style scoped>
.word-break-normal {
  word-break: normal;
}
</style>
