<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.02 24">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="_004-age-limit" data-name=" 004-age-limit">
          <path
            id="Path_405"
            data-name="Path 405"
            d="M9.32,19a.47.47,0,0,0,.47-.47V5.67a.47.47,0,0,0-.47-.47H6.57a.47.47,0,0,0,0,.94H8.85V18.52A.47.47,0,0,0,9.32,19Z"
          />
          <path
            id="Path_406"
            data-name="Path 406"
            d="M11.46,14.62a4.18,4.18,0,1,0,5.95-3.79A3.17,3.17,0,1,0,13,10a3.24,3.24,0,0,0,.87.86A4.18,4.18,0,0,0,11.46,14.62ZM13.4,8.19a2.24,2.24,0,1,1,2.24,2.24A2.24,2.24,0,0,1,13.4,8.19Zm2.24,3.18a3.25,3.25,0,1,1-3.24,3.25,3.26,3.26,0,0,1,3.24-3.25Z"
          />
          <path
            id="Path_407"
            data-name="Path 407"
            d="M31.55,11H28V7.49A.47.47,0,0,0,27.54,7a.48.48,0,0,0-.47.47V11H23.53a.47.47,0,1,0,0,.94h3.54v3.54a.48.48,0,0,0,.47.47.47.47,0,0,0,.47-.47V12h3.54a.47.47,0,0,0,0-.94Z"
          />
          <path
            id="Path_408"
            data-name="Path 408"
            d="M27.54,17.55a.47.47,0,0,0-.47.47h0v3.51a1.53,1.53,0,0,1-1.53,1.53H2.47A1.53,1.53,0,0,1,.94,21.53V2.47A1.54,1.54,0,0,1,2.47.94H25.54a1.54,1.54,0,0,1,1.53,1.53V5A.47.47,0,1,0,28,5V2.47A2.48,2.48,0,0,0,25.54,0H2.47A2.48,2.48,0,0,0,0,2.47V21.53A2.48,2.48,0,0,0,2.47,24H25.54A2.48,2.48,0,0,0,28,21.53V18A.47.47,0,0,0,27.54,17.55Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "age-limit",
};
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
